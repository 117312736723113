<template>
  <div class="page">
    <Head :headTitle="headTitle" />
    <div class="b-container">
      <div class="con-l">
        <div class="mod1 flex flex-v">
          <div class="b-pub-tt flex">
            <span class="bt">新亨电商产业</span>
          </div>
          <div class="mod2-b">
            <div class="col">
              <div class="b-smtit">
                <span class="bt">企业数量</span>
              </div>
              <div class="chart" ref="chart8"></div>
            </div>
          </div>
          <div class="mod1-b flex-11">
            <div class="col col1">
              <div class="b-smtit">
                <span class="bt">电商各产品订单种类占比图表</span>
              </div>
              <div class="chart" ref="chart1"></div>
            </div>
            <div class="col col2">
              <div class="b-smtit">
                <span class="bt">电商产业年度发展趋势</span>
              </div>
              <div class="chart" ref="chart2"></div>
            </div>
          </div>
        </div>

        <div class="mod3">
          <div class="b-pub-tt flex">
            <span class="bt">电商发展展示</span>
          </div>
          <ul class="list">
            <li class="item1" v-for="item in xiangcunList" :key="item.img">
              <div class="imgbox">
                <img :src="item.img" class="img img_full" alt="" />
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="con-c flex flex-v relative">
        <div class="mod-cen">
          <div class="cen-menu">
            <span class="lk">智慧城市</span>
            <span class="lk">数字乡村振兴</span>
            <span class="lk cur">新亨电商</span>
            <span class="lk">智慧文旅</span>
          </div>
          <div class="cen-top">
            <li>
              <span class="tit">从业人员(人)</span>
              <p class="num">{{ webData.people }}</p>
            </li>
            <li class="line"></li>
            <li>
              <span class="tit">年度产值(万元)</span>
              <p class="num">{{ webData.grossAnnVal }}</p>
            </li>
            <li class="line"></li>
            <li>
              <span class="tit">年度总销售额(万元)</span>
              <p class="num">{{ webData.annualGro }}</p>
            </li>
          </div>

          <div class="mod2-map">
            <!-- <img src="../assets/images/wt8.png" class="pic" alt="" /> -->
            <div class="item item1" :style="randomNum == 0 ? 'opacity:1;' : ''">
              <div class="b-smtit">
                <span class="bt">在 途 产 品 数</span>
              </div>
              <div class="txt">
                <p>
                  <span class="tit">本月已发产品数</span>
                  <em>{{ ProductTra.amount }}吨</em>
                </p>
                <p>
                  <span class="tit">本月待发产品数</span>
                  <em>{{ ProductTra.quantity }}吨</em>
                </p>
              </div>
            </div>
            <div class="item item2" :style="randomNum == 1 ? 'opacity:1;' : ''">
              <div class="b-smtit">
                <span class="bt"> 加 工 工 厂</span>
              </div>
              <div class="txt">
                <p>
                  <span class="tit">加工工厂数量</span>
                  <em>{{ industrialPark.amount }}间</em>
                </p>
                <p>
                  <span class="tit">加工产品种类</span>
                  <em>{{ industrialPark.quantity }}种</em>
                </p>
                <p>
                  <span class="tit">产品数量</span>
                  <em>{{ industrialPark.variety }}吨</em>
                </p>
              </div>
            </div>
            <div class="item item3" :style="randomNum == 2 ? 'opacity:1;' : ''">
              <div class="b-smtit">
                <span class="bt"> 工 业 园 区</span>
              </div>
              <div class="txt">
                <p>
                  <span class="tit">工业园数量</span>
                  <em>{{ millworkPlant.amount }}间</em>
                </p>
                <p>
                  <span class="tit">工业园产品种类</span>
                  <em>{{ millworkPlant.quantity }}种</em>
                </p>
                <p>
                  <span class="tit">产品数量</span>
                  <em>{{ millworkPlant.variety }}吨</em>
                </p>
              </div>
            </div>
          </div>
          <div class="mod2-bot">
            <div class="col col1">
              <div class="b-smtit">
                <span class="bt">运输方式</span>
              </div>
              <div class="chart" ref="chart3"></div>
            </div>
            <div class="col col2 flex flex-v">
              <div class="b-smtit">
                <span class="bt">工厂列表</span>
              </div>
              <div class="scroll-list flex-1 flex flex-v">
                <div class="row-t">
                  <div style="width: 43%">工厂名称</div>
                  <div style="width: 35%">加工产品种类</div>
                  <div style="width: 22%">产品数量</div>
                </div>
                <div class="scrollbb">
                  <vue-seamless-scroll
                    :data="factoryList"
                    :class-option="scrollOption"
                    class="scrollb"
                  >
                    <div class="scrollbox flex-1">
                      <div
                        class="row"
                        v-for="(item, index) in factoryList"
                        :key="index"
                      >
                        <div style="width: 43%">{{ item.title }}</div>

                        <div style="width: 35%">{{ item.cata }}</div>
                        <div style="width: 22%">{{ item.amount }}</div>
                      </div>
                    </div>
                  </vue-seamless-scroll>
                  <span class="linebg"></span>
                </div>
              </div>
            </div>
            <div class="col col3 flex flex-v">
              <div class="b-smtit">
                <span class="bt"> 产 业 园 区</span>
              </div>
              <div class="scroll-list flex-1 flex flex-v">
                <div class="row-t">
                  <div style="width: 48%">园区名称</div>
                  <div style="width: 30%">产品种类</div>
                  <div style="width: 22%">产品数量</div>
                </div>
                <div class="scrollbb">
                  <vue-seamless-scroll
                    :data="yuanquList"
                    :class-option="scrollOption"
                    class="scrollb"
                  >
                    <div class="scrollbox flex-1">
                      <div
                        class="row"
                        v-for="(item, index) in yuanquList"
                        :key="index"
                      >
                        <div style="width: 48%">{{ item.title }}</div>

                        <div style="width: 30%">{{ item.cata }}</div>
                        <div style="width: 22%">{{ item.amount }}</div>
                      </div>
                    </div>
                  </vue-seamless-scroll>
                  <span class="linebg"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="con-r">
        <div class="mod4 flex flex-v">
          <div class="b-pub-tt flex">
            <span class="bt">新亨物流产业概况</span>
          </div>
          <div class="mod4-b flex-11">
            <div class="col col1">
              <div class="b-smtit">
                <span class="bt">物 流 公 司 占 比 图 表</span>
              </div>
              <div class="chart" ref="chart4" style="height: 100%"></div>
            </div>
            <div class="col col2">
              <div class="b-smtit">
                <span class="bt">物 流 产 业 年 度 发 展 趋 势</span>
              </div>
              <div class="chart" ref="chart5"></div>
            </div>
          </div>
          <div class="mod4-b flex-11">
            <div class="col col1">
              <div class="b-smtit">
                <span class="bt">新 亨 物 流 的 全 省 占 比 图 表</span>
              </div>
              <div class="chart" ref="chart6" style="height: 100%"></div>
            </div>
            <div class="col col2">
              <div class="b-smtit">
                <span class="bt">各 物 流 收 发 量 每 日 排 名</span>
              </div>
              <ul class="bar-list">
                <li
                  class="row"
                  v-for="(item, index) in chucunshuliangList"
                  :key="index"
                >
                  <span class="num">{{ index + 1 }}</span>
                  <div class="smbox">
                    <div class="font">
                      <span>{{ item.name }}</span>
                      <span style="color:#fc9d1f ;">{{ item.num }}</span>
                      <span>万件</span>
                    </div>
                    <div class="progress">
                      <i :style="{ width: item.widthVar }"></i>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="mod5 flex flex-v">
          <div class="b-pub-tt flex">
            <span class="bt">仓储概况</span>
          </div>
          <div class="b-smtit">
            <span class="bt">仓库数据统计</span>
          </div>
          <ul class="mod5-gk">
            <li>
              <img src="../assets/images/sy_15.png" class="icon" alt="" />
              <div class="info">
                <h6 class="tit">仓库总数</h6>
                <p class="num" style="color: #489ffe">
                  {{ warehouseQuantity.totalWar }}个
                </p>
              </div>
            </li>
            <li>
              <img src="../assets/images/sy_16.png" class="icon" alt="" />
              <div class="info">
                <h6 class="tit">仓库总面积</h6>
                <p class="num" style="color: #85b1ff">
                  {{ warehouseQuantity.totalWarehouseArea }}平方
                </p>
              </div>
            </li>
            <li>
              <img src="../assets/images/sy_17.png" class="icon" alt="" />
              <div class="info">
                <h6 class="tit">仓库储存量</h6>
                <p class="num" style="color: #90ffad">
                  {{ warehouseQuantity.warehouseSto }}吨
                </p>
              </div>
            </li>
            <li>
              <img src="../assets/images/sy_18.png" class="icon" alt="" />
              <div class="info">
                <h6 class="tit">仓库每天收发数量</h6>
                <p class="num" style="color: #ffc55a">
                  {{ warehouseQuantity.quantitySentAndRec }}万
                </p>
              </div>
            </li>
            <li>
              <img src="../assets/images/sy_19.png" class="icon" alt="" />
              <div class="info">
                <h6 class="tit">对接的城市数量</h6>
                <p class="num" style="color: #45f5ff">
                  {{ warehouseQuantity.dockingCity }}个
                </p>
              </div>
            </li>
          </ul>
          <div class="mod5-sl">
            <div class="col col1">
              <div class="b-smtit">
                <span class="bt">仓库数量</span>
              </div>
              <div class="hang">
                普通仓库数量 <span>{{ warehouseQua.freeWar }}</span>
              </div>
              <div class="hang">
                恒温仓库数量 <span>{{ warehouseQua.isotemperatureWar }}</span>
              </div>
              <div class="hang">
                冷藏仓库数量 <span>{{ warehouseQua.refrigeratedWar }}</span>
              </div>
            </div>
            <div class="col col3">
              <div class="b-smtit">
                <span class="bt">仓储产品数量</span>
              </div>
              <div class="chart" ref="chart7"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Head from "@/components/head_big.vue";
let Echarts = require("echarts/lib/echarts");
import { loopShowTooltip } from "../assets/js/tooltip-auto-show-vue.js";
import axios from "axios";
export default {
  data() {
    return {
      headTitle: "",
      industryDetails: {},
      randomNum: 0,
      swiper: [],
      // wenmingList: [
      //   {
      //     img: require("../assets/images/wt12.png"),
      //   },
      // ],
      xiangcunList: [
        {
          img: "https://media.shouxinfund.com/jieyang/wt12.jpg"
        },
        {
          img: "https://media.shouxinfund.com/jieyang/wt13.jpg",
        },
        {
          img: "https://media.shouxinfund.com/jieyang/wt14.jpg",
        },
        {
          img: "https://media.shouxinfund.com/jieyang/wt15.jpg",
        },
        {
          img: "https://media.shouxinfund.com/jieyang/wt16.jpg",
        },
      ],
      chucunshuliangList: [
        {
          name: "",
          num: "",
          widthVar: "",
        },
        {
          name: "",
          num: "",
          widthVar: "",
        },
        {
          name: "",
          num: "",
          widthVar: "",
        },
      ],
      factoryList: [
        {
          title: "",
          cata: "",
          amount: "",
        },
        {
          title: "",
          cata: "",
          amount: "",
        },
        {
          title: "",
          cata: "",
          amount: "",
        },
        {
          title: "",
          cata: "",
          amount: "",
        },
        {
          title: "",
          cata: "",
          amount: "",
        },
      ],
      yuanquList: [
        {
          title: "",
          cata: "",
          amount: "",
        },
        {
          title: "",
          cata: "",
          amount: "",
        },
        {
          title: "",
          cata: "",
          amount: "",
        },
        {
          title: "",
          cata: "",
          amount: "",
        },
        {
          title: "",
          cata: "",
          amount: "",
        },
      ],
      chart1: null,
      tootipTimer1:'',
      chart2: null,
      chart3: null,
      chart4: null,
      chart5: null,
      chart6: null,
      chart7: null,
      chart8: null,
      webData: {},
      productOrder: [],
      supplyTrend: {},
      modeofTra: [],
      logisticsCom: [],
      logisticsInd: {},
      sunhangLog: [],
      warehousePro: [],
      industryDetails: {},
      factoryList: [],
      industrialParks: [],
      dailyRan: [],
      warehouseQuantity: {},
      warehouseQua: {},
      industrialPark: {},
      loaded: false,
      ProductTra: {},
      millworkPlant: {},
    };
  },
  components: {
    Head,
  },
  computed: {
    scrollOption() {
      return {
        step: 0.3, // 数值越大速度滚动越快
        limitMoveNum: 3, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        // openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
    // scrollOption1() {
    //   return {
    //     step: 1, // 数值越大速度滚动越快
    //     // limitMoveNum: 10, // 开始无缝滚动的数据量 this.dataList.length
    //     hoverStop: true, // 是否开启鼠标悬停stop
    //     direction: 1, // 0向下 1向上 2向左 3向右
    //     // openWatch: true, // 开启数据实时监控刷新dom
    //     singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
    //     singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
    //     waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
    //   };
    // },
  },
  created() {
    this.getData();
  },
  mounted() {
    setInterval(() => {
      this.randomNum = Math.floor(Math.random() * 3);
    }, 3000);
    this.init8();
    this.loaded = true;
    
    this.getproductOrder();
    this.getsupplyTrend();
    this.getmodeofTra();
    this.getlogisticsCom();
    this.getlogisticsInd();
    this.getsunhangLog();
    this.getwarehousePro();

    window.addEventListener("resize", () => {
      this.chart1.resize();
      this.chart2.resize();
      this.chart3.resize();
      this.chart4.resize();
      this.chart5.resize();
      this.chart6.resize();
      this.chart7.resize();
      this.chart8.resize();
    });
    // this.swiper = new Swiper(".swiper-container.swiper-container1", {
    //   slidesPerView: 3,
    //   speed:3000,
    //   autoplay:true,
    //   spaceBetween: 30,
    //   centeredSlides: true,
    //   loop: true,
    // });
  },

  methods: {
    getData() {
      axios
        .get("/10xhds.json",{
          params:{
            // timestamp:new Date().getTime()
            random:Math.random()
          }
        })
        .then((res) => {
          let dataObject = res.data;
          let jsonString = JSON.stringify(dataObject);
          let jsonObject = JSON.parse(jsonString);
          this.webData = jsonObject;
          this.headTitle = jsonObject.bigTitle;
          this.chucunshuliangList = jsonObject.dailyRan;
          this.factoryList = jsonObject.factoryList;
          this.yuanquList = jsonObject.industrialParks;
          console.log("jsonObject=========>", jsonObject);
          this.industryDetails = jsonObject.industryDetails;
          this.warehouseQua = jsonObject.warehouseQua;
          this.warehouseQuantity = jsonObject.warehouseQuantity;
          this.ProductTra = jsonObject.ProductTra;
          this.industrialPark = jsonObject.industrialPark;
          this.millworkPlant = jsonObject.millworkPlant;
        })
        .catch((error) => {
          console.log(error.response.status);
          console.log(error.response.data);
          console.log(error.response.headers);
        });
    },
    async getproductOrder() {
      const res = await axios.get("/10xhds.json");
      let dataObject = res.data;
      let jsonString = JSON.stringify(dataObject);
      let jsonObject = JSON.parse(jsonString);
      this.productOrder = jsonObject.productOrder;
      this.init1();
    },
    init1() {
      this.chart1 = Echarts.init(this.$refs.chart1);
      let chartData = [];
      chartData = this.productOrder;
      const sum = chartData.reduce((per, cur) => per + cur.value, 0);
      let option = {
        title: {
          text: "{a|电商种类总数}\n\n{b|" + sum + "种}",
          top: "center",
          textStyle: {
            rich: {
              a: {
                fontSize: nowSize(25),
                padding: [nowSize(-10), 0, 0, nowSize(70)],
                color: "#fff",
                lineHeight: 1,
                fontFamily: "YouSheBiaoTiHei",
              },
              b: {
                fontSize: nowSize(25),
                padding: [nowSize(-10), 0, 0, nowSize(70)],
                color: "#45F5FF",
                lineHeight: 1,
                fontFamily: "YouSheBiaoTiHei",
              },
            },
          },
        },
        tooltip: {
          trigger: "item",
          backgroundColor: "rgba(0,0,0,.6)",
          textStyle: {
            fontSize: nowSize(12),
            color: "#fff",
          },
          extraCssText: "border:1px solid #3FF5FF",
          axisPointer: {
            crossStyle: {
              color: "#fff",
            },
          },
        },
        grid: {
          left: "0",
          right: "0",
          bottom: "0",
          top: "0",
          containLabel: true,
        },
        legend: {
          x: "right",
          y: "center",
          padding:[0,30,0,0],
          orient: "vertial",
          icon: "rect",
          itemWidth: nowSize(20),
          itemHeight: nowSize(20),
          textStyle: {
            color: "#fff",
            fontSize: nowSize(16),
            lineHeight: nowSize(30),
          },
          itemGap: nowSize(20),
          formatter: (name) => {
            console.log(name);
            console.log(chartData);
            const item = chartData.find((i) => {
              return i.name === name;
            });
            const p = ((item.value / sum) * 100).toFixed(0);
            const value = item.value;
            return "{name|" + name + "}" + "{value|" + value + "种"+"}" + "{percent|" + p + "%}";//+ "{value|" + value + "种"+"}"
          },
          textStyle: {
            rich: {
              name: {
                fontSize: nowSize(25),
                height: nowSize(20),
                padding: [0, nowSize(15), 0, 0],
                color: "#fff",
              },

              value: {
                fontSize: nowSize(25),
                height: nowSize(20),
                padding: [0, nowSize(15), 0, 0],
                color: "#fc9d1f",
              },

              percent: {
                fontSize: nowSize(25),
                height: nowSize(20),
                color: "#fff",
              },
            },
          },
        },

        color: ["rgb(86,230,215)", "rgb(51,120,255)", "rgb(210,61,226)"],
        series: [
          {
            name: "",
            type: "pie",
            radius: ["77%", "90%"],
            center: ["25%", "48%"],
            avoidLabelOverlap: false,
            hoverAnimation: true,
            label: {
              normal: {
                show: false,
              },
            },
            itemStyle: {
              borderRadius: 0,
              borderColor: "#031232",
              borderWidth: nowSize(2),
            },
            labelLine: {
              show: false,
            },
            data: chartData,
          },
          {
            type: "pie",
            name: "内层细圆环",
            radius: ["61%", "63%"],
            center: ["25%", "48%"],
            hoverAnimation: false,
            clockWise: true,
            itemStyle: {
              normal: {
                color: "#48b1ea",
              },
            },
            tooltip: {
              show: false,
            },
            label: {
              show: false,
            },
            data: [1],
          },
        ],
      };
      this.chart1.setOption(option, true);

      this.tootipTimer1 && this.tootipTimer1.clearLoop();
      this.tootipTimer1 = 0;
      this.tootipTimer1 = loopShowTooltip(this.chart1, option, {});
    },
    async getsupplyTrend() {
      const res = await axios.get("/10xhds.json");
      let dataObject = res.data;
      let jsonString = JSON.stringify(dataObject);
      let jsonObject = JSON.parse(jsonString);
      this.supplyTrend = jsonObject.supplyTrend;
      this.init2();
    },

    init2() {

      this.chart2 = Echarts.init(this.$refs.chart2);
      let option = {
        title: {
          text: "单位为:万吨",
          right: 5,
          top: 0,
          textStyle: {
            fontSize: nowSize(22),
            color: "#fff",
          },
        },
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(0,0,0,.6)",
          textStyle: {
            fontSize: nowSize(12),
            color: "#fff",
          },
          extraCssText: "border:1px solid #3FF5FF",
          axisPointer: {
            crossStyle: {
              color: "#fff",
            },
          },
        },
        toolbox: {
          show: true,
        },
        grid: {
          left: nowSize(20),
          right: nowSize(40),
          bottom: nowSize(10),
          top: nowSize(20),
          containLabel: true,
        },

        // legend: {
        // 	x: 'right',
        // 	y: 'top',
        // 	icon:'rect',
        // 	itemWidth:nowSize(14),
        // 	itemHeight:nowSize(14),
        // 	textStyle: {
        // 		color: '#fff',
        // 		fontSize: nowSize(18)
        // 	},
        // },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            // data: ['1月','2月','3月','4月','5月','6月','7月','8月','9月','10月','11月','12月'],
            data: this.supplyTrend.month,
            axisPointer: {
              type: "",
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#fff",
                fontSize: nowSize(18),
              },
              //rotate: '0',
              interval: 0,
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#023748", //更改坐标轴颜色
              },
            },
            splitLine: {
              show: false,
              lineStyle: {
                color: ["#edf2f8"],
                width: 1,
                type: "dashed",
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "",
            position: "left",
            min: 0,
            //max: 6000,
            // interval: 1000,
            nameTextStyle: {
              color: "#fff",
              fontSize: nowSize(18),
              padding: [0, 0, 0, -20],
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#fff", //更改坐标轴文字颜色
                fontSize: nowSize(18), //更改坐标轴文字大小
              },
              //formatter: '{value}%'
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#023748", //更改坐标轴颜色
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: ["#023748"],
                width: 1,
                type: "solid",
              },
            },
          },
        ],

        series: [
          {
            name: "",
            type: "line",
            // data: [2000,2700,3000,2600,1800,1500,2100,3600,4100,2500,2000,3500],
            data: this.supplyTrend.data,
            smooth: true,
            symbolSize: 1, //折线点的大小
            itemStyle: {
              normal: {
                color: "#FD9D20", //折线点的颜色
                borderWidth: 1,
                lineStyle: {
                  color: "#FD9D20", //折线的颜色
                },
                areaStyle: {
                  color: new Echarts.graphic.LinearGradient(0, 1, 0, 0, [
                    {
                      offset: 0,
                      color: "rgba(235,157,32,0)",
                    },
                    {
                      offset: 1,
                      color: "rgba(235,157,32,.6)",
                    },
                  ]),
                },
              },
            },
          },
        ],
      };
      this.chart2.setOption(option, true);

      this.tootipTimer2 && this.tootipTimer2.clearLoop();
      this.tootipTimer2 = 0;
      this.tootipTimer2 = loopShowTooltip(this.chart2, option, {});
    },
    async getmodeofTra() {
      const res = await axios.get("/10xhds.json");
      let dataObject = res.data;
      let jsonString = JSON.stringify(dataObject);
      let jsonObject = JSON.parse(jsonString);
      this.modeofTra = jsonObject.modeofTra;
      this.init3();
    },

    init3() {
      this.chart3 = Echarts.init(this.$refs.chart3);
      let chartData = [];
      chartData = this.modeofTra;
      const sum = chartData.reduce((per, cur) => per + cur.value, 0);
      let option = {
        title: {
          text: "{a|占比最大}\n\n{b|空路运输}",
          top: "center",
          textStyle: {
            rich: {
              a: {
                fontSize: nowSize(20),
                padding: [0, 0, 0, nowSize(40)],
                color: "#fff",
                lineHeight: 1,
                fontFamily: "YouSheBiaoTiHei",
              },
              b: {
                fontSize: nowSize(25),
                padding: [nowSize(-10), 0, 0, nowSize(30)],
                color: "#45F5FF",
                lineHeight: 1,
                fontFamily: "YouSheBiaoTiHei",
              },
            },
          },
        },
        tooltip: {
          trigger: "item",
          backgroundColor: "rgba(0,0,0,.6)",
          textStyle: {
            fontSize: nowSize(12),
            color: "#fff",
          },
          extraCssText: "border:1px solid #3FF5FF",
          axisPointer: {
            crossStyle: {
              color: "#fff",
            },
          },
        },
        grid: {
          left: "0",
          right: "0",
          bottom: "0",
          top: "0",
          containLabel: true,
        },
        legend: {
          x: "right",
          y: "center",
          orient: "vertial",
          icon: "rect",
          itemWidth: nowSize(20),
          itemHeight: nowSize(20),
          textStyle: {
            color: "#fff",
            fontSize: nowSize(18),
            lineHeight: nowSize(30),
          },
          itemGap: nowSize(15),
          formatter: (name) => {
            console.log(name);
            console.log(chartData);
            const item = chartData.find((i) => {
              return i.name === name;
            });
            const p = ((item.value / sum) * 100).toFixed(0);
            const value = item.value;
            return (
              "{name|" +
              name +
              "}" +
              "{value|" +
              value +
              "万单}" +
              "{percent|" +
              p +
              "%}"
            );
          },
          textStyle: {
            rich: {
              name: {
                fontSize: nowSize(18),
                height: nowSize(20),
                width: nowSize(80),
                // padding: [0, nowSize(15), 0, 0],
                color: "#fff",
              },
              value: {
                fontSize: nowSize(18),
                height: nowSize(20),
                width: nowSize(90),
                // padding: [0, nowSize(15), 0, 0],
                color: "#45F5FF",
              },
              percent: {
                fontSize: nowSize(18),
                height: nowSize(20),
                color: "#fff",
              },
            },
          },
        },
        color: ["rgb(86,230,215)", "rgb(51,120,255)","rgb(210,61,226)"],
        series: [
          {
            name: "",
            type: "pie",
            radius: ["52%", "65%"],
            center: ["25%", "48%"],
            avoidLabelOverlap: false,
            hoverAnimation: true,
            label: {
              normal: {
                show: false,
              },
            },
            itemStyle: {
              borderRadius: 0,
              borderColor: "#031232",
              borderWidth: nowSize(2),
            },
            labelLine: {
              show: false,
            },
            data: chartData,
          },
          {
            type: "pie",
            name: "内层细圆环",
            radius: ["46%", "48%"],
            center: ["25%", "48%"],
            hoverAnimation: false,
            clockWise: true,
            itemStyle: {
              normal: {
                color: "#178ac9",
              },
            },
            tooltip: {
              show: false,
            },
            label: {
              show: false,
            },
            data: [1],
          },
        ],
      };
      this.chart3.setOption(option, true);

      this.tootipTimer3 && this.tootipTimer3.clearLoop();
      this.tootipTimer3 = 0;
      this.tootipTimer3 = loopShowTooltip(this.chart3, option, {});
    },
    async getlogisticsCom() {
      const res = await axios.get("/10xhds.json");
      let dataObject = res.data;
      let jsonString = JSON.stringify(dataObject);
      let jsonObject = JSON.parse(jsonString);
      this.logisticsCom = jsonObject.logisticsCom;
      this.init4();
    },

    init4() {
      this.chart4 = Echarts.init(this.$refs.chart4);
      let chartData = [];
      chartData = this.logisticsCom;
      const sum = chartData.reduce((per, cur) => per + cur.value, 0);
      let option = {
        title: {
          text: "{a|物流公司总数}\n\n{b|" + sum + "个}",
          top: "center",
          textStyle: {
            rich: {
              a: {
                fontSize: nowSize(25),
                padding: [nowSize(-10), 0, 0, nowSize(70)],
                color: "#fff",
                lineHeight: 1,
                fontFamily: "YouSheBiaoTiHei",
              },
              b: {
                fontSize: nowSize(30),
                padding: [nowSize(-10), 0, 0, nowSize(70)],
                color: "#45F5FF",
                lineHeight: 1,
                fontFamily: "YouSheBiaoTiHei",
              },
            },
          },
        },
        tooltip: {
          trigger: "item",
          backgroundColor: "rgba(0,0,0,.6)",
          textStyle: {
            fontSize: nowSize(12),
            color: "#fff",
          },
          extraCssText: "border:1px solid #3FF5FF",
          axisPointer: {
            crossStyle: {
              color: "#fff",
            },
          },
        },
        grid: {
          left: "0",
          right: "0",
          bottom: "0",
          top: "0",
          containLabel: true,
        },
        legend: {
          x: "right",
          y: "center",
          orient: "vertial",
          icon: "rect",
          itemWidth: nowSize(20),
          itemHeight: nowSize(20),
          textStyle: {
            color: "#fff",
            fontSize: nowSize(16),
            lineHeight: nowSize(30),
          },
          itemGap: nowSize(20),
          formatter: (name) => {
            console.log(name);
            console.log(chartData);
            const item = chartData.find((i) => {
              return i.name === name;
            });
            const p = ((item.value / sum) * 100).toFixed(0);
            const value = item.value;
            return "{name|" + name + "}"+  "{value|" + value + "万个" +"}"  + "{percent|" + p + "%}";//+ "{value|" + value + "万个" +"}" 
          },
          textStyle: {
            rich: {
              name: {
                fontSize: nowSize(25),
                height: nowSize(20),
                padding: [0, nowSize(15), 0, 0],
                color: "#fff",
              },

              value: {
                fontSize: nowSize(25),
                height: nowSize(20),
                padding: [0, nowSize(15), 0, 0],
                color: "#fc9d1f",
              },

              percent: {
                fontSize: nowSize(25),
                height: nowSize(20),
                color: "#fff",
              },
            },
          },
        },

        color: ["rgb(86,230,215)", "rgb(51,120,255)", "rgb(255,233,156)", "rgb(210,61,226)"],
        series: [
          {
            name: "",
            type: "pie",
            radius: ["52%", "65%"],
            center: ["25%", "48%"],
            avoidLabelOverlap: false,
            hoverAnimation: true,
            label: {
              normal: {
                show: false,
              },
            },
            itemStyle: {
              borderRadius: 0,
              borderColor: "#031232",
              borderWidth: nowSize(2),
            },
            labelLine: {
              show: false,
            },
            data: chartData,
          },
          {
            type: "pie",
            name: "内层细圆环",
            radius: ["46%", "48%"],
            center: ["25%", "48%"],
            hoverAnimation: false,
            clockWise: true,
            itemStyle: {
              normal: {
                color: "#48b1ea",
              },
            },
            tooltip: {
              show: false,
            },
            label: {
              show: false,
            },
            data: [1],
          },
        ],
      };
      this.chart4.setOption(option, true);

      this.tootipTimer4 && this.tootipTimer4.clearLoop();
      this.tootipTimer4 = 0;
      this.tootipTimer4 = loopShowTooltip(this.chart4, option, {});
    },
    async getlogisticsInd() {
      const res = await axios.get("/10xhds.json");
      let dataObject = res.data;
      let jsonString = JSON.stringify(dataObject);
      let jsonObject = JSON.parse(jsonString);
      this.logisticsInd = jsonObject.logisticsInd;

      console.log(this.logisticsInd.data);
      this.init5();
    },

    init5() {
      console.log(this.logisticsInd.data);
      this.chart5 = Echarts.init(this.$refs.chart5);
      let option = {
        title: {
          text: "单位为:万件",
          right: nowSize(10),
          top: nowSize(1),
          textStyle: {
            fontSize: nowSize(22),
            color: "#fff",
          },
        },
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(0,0,0,.6)",
          textStyle: {
            fontSize: nowSize(12),
            color: "#fff",
          },
          extraCssText: "border:1px solid #FD9D20",
          axisPointer: {
            crossStyle: {
              color: "#fff",
            },
          },
        },
        toolbox: {
          show: true,
        },
        grid: {
          left: nowSize(20),
          right: nowSize(40),
          bottom: nowSize(10),
          top: nowSize(20),
          containLabel: true,
        },

        // legend: {
        // 	x: 'right',
        // 	y: 'top',
        // 	icon:'rect',
        // 	itemWidth:nowSize(14),
        // 	itemHeight:nowSize(14),
        // 	textStyle: {
        // 		color: '#fff',
        // 		fontSize: nowSize(18)
        // 	},
        // },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            data: [
              "1月",
              "2月",
              "3月",
              "4月",
              "5月",
              "6月",
              "7月",
              "8月",
              "9月",
              "10月",
              "11月",
              "12月",
            ],
            axisPointer: {
              type: "",
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#fff",
                fontSize: nowSize(18),
              },
              //rotate: '0',
              interval: 0,
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#023748", //更改坐标轴颜色
              },
            },
            splitLine: {
              show: false,
              lineStyle: {
                color: ["#edf2f8"],
                width: 1,
                type: "dashed",
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "",
            position: "left",
            min: 0,
            //max: 6000,
            // interval: 1000,
            nameTextStyle: {
              color: "#fff",
              fontSize: nowSize(18),
              padding: [0, 0, 0, -20],
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#fff", //更改坐标轴文字颜色
                fontSize: nowSize(18), //更改坐标轴文字大小
              },
              //formatter: '{value}%'
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#023748", //更改坐标轴颜色
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: ["#023748"],
                width: 1,
                type: "solid",
              },
            },
          },
        ],

        series: [
          {
            name: "",
            type: "line",
            // data: [2000,2700,3000,2600,1800,1500,2100,3600,4100,2500,2000,3500],
            data: this.logisticsInd.data,
            smooth: true,
            symbolSize: 1, //折线点的大小
            itemStyle: {
              normal: {
                color: "#FD9D20", //折线点的颜色
                borderWidth: 1,
                lineStyle: {
                  color: "#FD9D20", //折线的颜色
                },
                areaStyle: {
                  color: new Echarts.graphic.LinearGradient(0, 1, 0, 0, [
                    {
                      offset: 0,
                      color: "rgba(235,157,32,0)",
                    },
                    {
                      offset: 1,
                      color: "rgba(235,157,32,.6)",
                    },
                  ]),
                },
              },
            },
          },
        ],
      };
      this.chart5.setOption(option, true);

      this.tootipTimer5 && this.tootipTimer2.clearLoop();
      this.tootipTimer5 = 0;
      this.tootipTimer5 = loopShowTooltip(this.chart5, option, {});
    },
    async getsunhangLog() {
      const res = await axios.get("/10xhds.json");
      let dataObject = res.data;
      let jsonString = JSON.stringify(dataObject);
      let jsonObject = JSON.parse(jsonString);
      this.sunhangLog = jsonObject.sunhangLog;
      this.init6();
    },

    init6() {
      this.chart6 = Echarts.init(this.$refs.chart6);
      let chartData = [];
      chartData = this.sunhangLog;
      const sum = chartData.reduce((per, cur) => per + cur.value, 0);
      let option = {
        title: {
          text: "{a|全省物流总数}\n\n{b|" + sum + "万个}",
          top: "center",
          textStyle: {
            rich: {
              a: {
                fontSize: nowSize(25),
                padding: [nowSize(-10), 0, 0, nowSize(70)],
                color: "#fff",
                lineHeight: 1,
                fontFamily: "YouSheBiaoTiHei",
              },
              b: {
                fontSize: nowSize(30),
                padding: [nowSize(-10), 0, 0, nowSize(70)],
                color: "#45F5FF",
                lineHeight: 1,
                fontFamily: "YouSheBiaoTiHei",
              },
            },
          },
        },
        tooltip: {
          trigger: "item",
          backgroundColor: "rgba(0,0,0,.6)",
          textStyle: {
            fontSize: nowSize(12),
            color: "#fff",
          },
          extraCssText: "border:1px solid #3FF5FF",
          axisPointer: {
            crossStyle: {
              color: "#fff",
            },
          },
        },
        grid: {
          left: "0",
          right: "0",
          bottom: "0",
          top: "0",
          containLabel: true,
        },
        legend: {
          x: "right",
          y: "center",
          orient: "vertial",
          icon: "rect",
          itemWidth: nowSize(20),
          itemHeight: nowSize(20),
          textStyle: {
            color: "#fff",
            fontSize: nowSize(16),
            lineHeight: nowSize(30),
          },
          itemGap: nowSize(20),
          formatter: (name) => {
            console.log(name);
            console.log(chartData);
            const item = chartData.find((i) => {
              return i.name === name;
            });
            const p = ((item.value / sum) * 100).toFixed(0);
            const value = item.value;
            return "{name|" + name + "}"+  "{value|" + value + "万个" +"}"  + "{percent|" + p + "%}";//+ "{value|" + value + "万个" +"}" 
          },
          textStyle: {
            rich: {
              name: {
                fontSize: nowSize(25),
                height: nowSize(20),
                padding: [0, nowSize(15), 0, 0],
                color: "#fff",
              },

              value: {
                fontSize: nowSize(25),
                height: nowSize(20),
                padding: [0, nowSize(15), 0, 0],
                color: "#fc9d1f",
              },

              percent: {
                fontSize: nowSize(25),
                height: nowSize(20),
                color: "#fff",
              },
            },
          },
        },

        color: ["rgb(86,230,215)", "rgb(51,120,255)", "rgb(255,233,156)", "rgb(210,61,226)"],
        series: [
          {
            name: "",
            type: "pie",
            radius: ["52%", "65%"],
            center: ["25%", "48%"],
            avoidLabelOverlap: false,
            hoverAnimation: true,
            label: {
              normal: {
                show: false,
              },
            },
            itemStyle: {
              borderRadius: 0,
              borderColor: "#031232",
              borderWidth: nowSize(2),
            },
            labelLine: {
              show: false,
            },
            data: chartData,
          },
          {
            type: "pie",
            name: "内层细圆环",
            radius: ["46%", "48%"],
            center: ["25%", "48%"],
            hoverAnimation: false,
            clockWise: true,
            itemStyle: {
              normal: {
                color: "#48b1ea",
              },
            },
            tooltip: {
              show: false,
            },
            label: {
              show: false,
            },
            data: [1],
          },
        ],
      };
      this.chart6.setOption(option, true);

      this.tootipTimer6 && this.tootipTimer6.clearLoop();
      this.tootipTimer6 = 0;
      this.tootipTimer6 = loopShowTooltip(this.chart6, option, {});
    },
    async getwarehousePro() {
      const res = await axios.get("/10xhds.json");
      let dataObject = res.data;
      let jsonString = JSON.stringify(dataObject);
      let jsonObject = JSON.parse(jsonString);
      this.warehousePro = jsonObject.warehousePro;
      this.init7();
    },

    init7() {
      this.chart7 = Echarts.init(this.$refs.chart7);
      let chartData = [];
      chartData = this.warehousePro;
      const sum = chartData.reduce((per, cur) => per + cur.value, 0);
      let option = {
        title: {
          text: "{a|产品总数}\n\n{b|" + sum + "万吨}",
          top: "center",
          textStyle: {
            rich: {
              a: {
                fontSize: nowSize(25),
                padding: [nowSize(-10), 0, 0, nowSize(80)],
                color: "#fff",
                lineHeight: 1,
                fontFamily: "YouSheBiaoTiHei",
              },
              b: {
                fontSize: nowSize(25),
                padding: [nowSize(10), 0, 0, nowSize(80)],
                color: "#45F5FF",
                lineHeight: 1,
                fontFamily: "YouSheBiaoTiHei",
              },
            },
          },
        },
        tooltip: {
          trigger: "item",
          backgroundColor: "rgba(0,0,0,.6)",
          textStyle: {
            fontSize: nowSize(12),
            color: "#fff",
          },
          extraCssText: "border:1px solid #3FF5FF",
          axisPointer: {
            crossStyle: {
              color: "#fff",
            },
          },
        },
        grid: {
          left: "0",
          right: "0",
          bottom: "0",
          top: "0",
          containLabel: true,
        },
        legend: {
          x: "right",
          y: "center",
          padding:[0,30,0,0],
          orient: "vertial",
          icon: "rect",
          itemWidth: nowSize(20),
          itemHeight: nowSize(20),
          textStyle: {
            color: "#fff",
            fontSize: nowSize(16),
            lineHeight: nowSize(30),
          },
          itemGap: nowSize(20),
          formatter: (name) => {
            console.log(name);
            console.log(chartData);
            const item = chartData.find((i) => {
              return i.name === name;
            });
            const p = ((item.value / sum) * 100).toFixed(0);
            const value = item.value;
            return "{name|" + name + "}" + "{value|" + value + "种"+"}" + "{percent|" + p + "%}";//+ "{value|" + value + "种"+"}"
          },
          textStyle: {
            rich: {
              name: {
                fontSize: nowSize(25),
                height: nowSize(20),
                padding: [0, nowSize(15), 0, 0],
                color: "#fff",
              },

              value: {
                fontSize: nowSize(25),
                height: nowSize(20),
                width:nowSize(50),
                padding: [0, nowSize(15), 0, 0],
                color: "#fc9d1f",
              },

              percent: {
                fontSize: nowSize(25),
                height: nowSize(20),
                color: "#fff",
              },
            },
          },
        },

        color: ["rgb(86,230,215)", "rgb(51,120,255)", "rgb(210,61,226)"],
        series: [
          {
            name: "",
            type: "pie",
            radius: ["52%", "65%"],
            center: ["25%", "48%"],
            avoidLabelOverlap: false,
            hoverAnimation: true,
            label: {
              normal: {
                show: false,
              },
            },
            itemStyle: {
              borderRadius: 0,
              borderColor: "#031232",
              borderWidth: nowSize(2),
            },
            labelLine: {
              show: false,
            },
            data: chartData,
          },
          {
            type: "pie",
            name: "内层细圆环",
            radius: ["46%", "48%"],
            center: ["25%", "48%"],
            hoverAnimation: false,
            clockWise: true,
            itemStyle: {
              normal: {
                color: "#48b1ea",
              },
            },
            tooltip: {
              show: false,
            },
            label: {
              show: false,
            },
            data: [1],
          },
        ],
      };
      this.chart7.setOption(option, true);

      this.tootipTimer7 && this.tootipTimer7.clearLoop();
      this.tootipTimer7 = 0;
      this.tootipTimer7 = loopShowTooltip(this.chart7, option, {});
    },
    init8() {
      this.chart8 = Echarts.init(this.$refs.chart8);
      let option = {
        title: {
              text: "单位为:个",
              top: nowSize(1),
              right: nowSize(1),
              textStyle: {
                fontSize: nowSize(24),
                color: "#fff",
              },
            },
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(0,0,0,.6)",
          textStyle: {
            fontSize: nowSize(12),
            color: "#fff",
          },
          extraCssText: "border:1px solid #3FF5FF",
          axisPointer: {
            crossStyle: {
              color: "#fff",
            },
          },
        },
        toolbox: {
          show: true,
        },
        grid: {
          left: nowSize(10),
          right: nowSize(10),
          bottom: nowSize(5),
          top: nowSize(20),
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            //boundaryGap: false,
            data: [
            "2015年",
              "2016年",
              "2017年",
              "2018年",
              "2019年",
              "2020年",
              "2021年",
              "2022年",
              "2023年",
            ],
            // data:chart6Year,
            axisPointer: {
              type: "",
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#fff",
                fontSize: nowSize(20),
              },
              //rotate: '0',
              interval: 0,
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#024570", //更改坐标轴颜色
              },
            },
            splitLine: {
              show: false,
              lineStyle: {
                color: ["#edf2f8"],
                width: 1,
                type: "dashed",
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "",
            position: "left",
            min: 0,
            max: 500,
            interval: 100,
            nameTextStyle: {
              color: "#fff",
              fontSize: nowSize(16),
              padding: [0, 0, 0, -20],
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#fff", //更改坐标轴文字颜色
                fontSize: nowSize(20), //更改坐标轴文字大小
              },
              //formatter: '{value}%'
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
              lineStyle: {
                color: "#45F5FF", //更改坐标轴颜色
              },
            },
            splitLine: {
              show: false,
              lineStyle: {
                color: ["#45F5FF"],
                width: 1,
                type: "solid",
              },
            },
          },
        ],

        series: [
          {
            name: "",
            type: "bar",
            stack: "Total",
            label: {
              show: true,
              position: "top",
              textStyle: {
                color: "#fff",
                fontSize: nowSize(16),
              },
            },
            showBackground: false,
            barWidth: nowSize(40),
            data: [292, 219, 285, 256, 345, 258, 356, 332,289],
            itemStyle: {
              normal: {
                barBorderRadius: 0,
                color: new Echarts.graphic.LinearGradient(0, 1, 0, 0, [
                  {
                    offset: 0,
                    color: "#024570",
                  },
                  {
                    offset: 1,
                    color: "#45F5FF",
                  },
                ]),
              },
            },
          },
        ],
      };
      this.chart8.setOption(option, true);
      this.tootipTimer4 && this.tootipTimer4.clearLoop();
      this.tootipTimer4 = 0;
      this.tootipTimer4 = loopShowTooltip(this.chart8, option, {});
    },
  },
};
</script>

<style scoped>
.page {
  background: url(https://home/ResImg/xh_map.png) no-repeat center;
  background-size: 100% 100%;
  background-color: #0a224b;
}

.mod1 {
  height: 75%;
  margin-bottom: 1%;
}
/* .mod2 {
  height: 40%;
  margin-bottom: 10px;
} */
.mod3 {
  height: 24%;
}
.mod4 {
  height: 55%;
  margin-bottom: 1%;
}
.mod5 {
  height: 44%;
}
/* .mod7 {
  height: calc(27% - 165px);
} */

.mod1-di {
  display: flex;
  justify-content: space-between;
}

/* .mod1-gk {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 18.3%;
}
.mod1-gk li {
  display: flex;
  align-items: center;
  width: 33%;
}
.mod1-gk .icon {
  width: 150px;
  height: 150px;
}
.mod1-gk .info {
  flex: 1;
  overflow: hidden;
}
.mod1-gk .tit {
  font-size: 20px;
  color: #fff;
}
.mod1-gk .num {
  font-size: 26px;
  color: #fff;
  font-family: "YouSheBiaoTiHei";
} */

.mod1-b {
  height: 25%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* margin-top: 10%; */
}

.mod1-b .b-smtit {
  margin-bottom: 0;
}

.mod1-b .chart {
  margin-top: 5%;
  height: calc(95% - 10px);
  flex: 1;
}

.mod1-b .grp {
  margin-top: 40px;
}
.mod1-b .grp .num {
  font-size: 24px;
  font-family: "YouSheBiaoTiHei";
}
.mod1-b .grp .fz {
  font-size: 18px;
  color: #fff;
}
.mod1-b .tip {
  font-size: 20px;
  color: #fff;
  margin-top: 30px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mod1-b .tip .star {
  width: 18px;
  display: inline-block;
  vertical-align: middle;
  margin-left: 5px;
}
.mod2-b {
  height: 50%;
  margin-top: 10px;
}
.mod1-b .tip .wz {
  font-size: 20px;
  color: red;
  margin-left: 5px;
}

.mod2-b .col {
  width: 100%;
  height: 100%;
}
/* .mod2-b .col1 {
  width: 40%;
  height: 40%;
}
.mod2-b .col2 {
  width: 50%;
}
.mod2-b .col3 {
  width: 27%;
  padding-left: 1%;
} */
.mod2-b .chart {
  /* height: calc(100% - 100px); */
  height: calc(85% - 10px);
  flex: 1;
}

.mod2-bot {
  position: absolute;
  width: calc(100% + 70px);
  left: -35px;
  bottom: 50px;
  display: flex;
  justify-content: space-between;
}
.mod2-bot .b-smtit {
  margin-bottom: 10px;
}
.mod2-bot .col {
  background-color: rgba(0, 48, 104, 0.21);
  border-radius: 10px;
  height: 300px;
  padding: 15px;
}
.mod2-bot .col1 {
  width: 32%;
}
.mod2-bot .col2 {
  width: 34%;
}
.mod2-bot .col3 {
  width: 34%;
}
.mod2-bot .chart {
  height: calc(90% - 10px);
}

.mod2-map {
  position: absolute;
  width: calc(100% + 300px);
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  margin-left: -150px;
}
.mod2-map .item {
  width: 400px;
  padding: 20px;
  border-radius: 10px;
  background: rgba(0, 48, 104, 0.48);
  position: absolute;
  opacity: 0;
}
.mod2-map .item .txt {
  font-size: 30px;
  color: #45f5ff;
  line-height: 50px;
  padding-top: 7px;
}
.mod2-map .item .txt p {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}
.mod2-map .item .tit {
  color: #fff;
}
.mod2-map .item .txt * {
  font-family: "YouSheBiaoTiHei";
}

.mod2-map .item1 {
  right: 15%;
  top: 20%;
}
.mod2-map .item2 {
  left: 10%;
  top: 30%;
}
.mod2-map .item3 {
  right: 20%;
  top: 40%;
}

.mod3 {
  width: calc(100% + 50px);
  /* height: 50%; */
}
.mod3 .list {
  margin-top: 1%;
  display: flex;
  justify-content: space-between;
}
.mod3 .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  transition: 300ms;
  transform: scale(0.8);
}
.swiper-slide-active,
.swiper-slide-duplicate-active {
  transform: scale(1);
}
.mod3 .img_full {
  width: 300px;
  height: 150px;
}

.mod3 .list .item1 {
  display: flex;
  align-items: end;
  width: 30%;
}

/* .mod3 .list .item1 .imgbox {
  height: 70%;
  width: 130%;
} */
.mod3 .list .imgbox {
  width: 210px;
  height: 135px;
  border: 4px solid #041f48;
  border-radius: 8px;
  margin-right: 10px;
}
.mod3 .list .info {
  flex: 1;
  overflow: hidden;
}
.mod3 .list .title {
  font-size: 21px;
  color: #fff;
  margin-bottom: 5px;
}
.mod3 .list .bot {
  display: flex;
  align-items: center;
}
.mod3 .list .num {
  font-size: 24px;
  color: #45f5ff;
  font-family: "YouSheBiaoTiHei";
}
.mod3 .list .level {
  font-size: 22px;
  color: #00ffb5;
  height: 34px;
  line-height: 34px;
  border: 1px solid #00ffb5;
  border-radius: 6px;
  margin-left: 10px;
  display: inline-block;
  vertical-align: middle;
  padding: 0 8px;
  font-family: "YouSheBiaoTiHei";
}

.mod4-b {
  height: 50%;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.mod4-b .b-smtit {
  margin-bottom: 0;
}
/* .mod4-b {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
} */
.mod4-b .col {
  display: flex;
  flex-direction: column;
  height: 100%;
}
/* .mod4-b .col1 {
  width: 40%;
  height: 80%;
} */
/* .mod4-b .col2 {
  width: 55%;
  height: 70%;
} */
/* .mod4-b .col3 {
  width: 28%;
  height: 220px;
} */
.mod4-b .chart {
  /* height: calc(100% - 100px); */
  height: calc(95% - 10px);
  flex: 1;
}
.mod4-b .bar-list .row {
  margin-bottom: 15px;
  padding: 15px;
}
.mod4-b .bar-list .row .num {
  padding: 10px;
  font-size: 35px;
}

.mod5-gk {
  min-height: 10vh;
}
.mod5-gk {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.mod5-gk li {
  display: flex;
  align-items: center;
}
.mod5-gk .icon {
  width: 80px;
}
.mod5-gk .info {
  flex: 1;
  overflow: hidden;
}
.mod5-gk .tit {
  font-size: 20px;
  color: #fff;
  white-space: nowrap;
}
.mod5-gk .num {
  font-size: 26px;
  color: #fff;
  font-family: "YouSheBiaoTiHei";
  white-space: nowrap;
}

.mod5-sl {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
/* .mod5-sl .col1 {
  width: 40%;
} */
/* .mod5-sl .col2 {
  width: 34%;
} */
/* .mod5-sl .col3 {
  width: 50%;
} */

.mod5-sl .hang {
  /* display: flex;
  justify-content: space-between; */
  font-size: 30px;
  color: #fff;
  line-height: 24px;
  padding: 25px;
}
.mod5-sl .hang span {
  font-size: 30px;
  font-family: "YouSheBiaoTiHei";
  margin-left: 15px;
}
.mod5-sl .hang:nth-child(2) span {
  color: #ffc55a;
}
.mod5-sl .hang:nth-child(3) span {
  color: #90ffad;
}
.mod5-sl .hang:nth-child(4) span {
  color: #14c1fd;
}

.mod5-sl .bar-list .row {
  margin-bottom: 15px;
}

.mod5-sl .chart {
  /* height: calc(100% - 40px); */
  height: calc(95% - 10px);
}

.con-c {
  position: relative;
}

.cen-top.b {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 30px;
  padding: 0 5%;
}

.cm {
  color: #ffd105;
}
.cy {
  color: #015aff;
}
</style>
